@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,800&family=Roboto:wght@400&display=swap');

.input_upload_file  {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    left: 0;
    height: 100%;
}
.button_upload_file {
    margin-bottom: 16px
}
.dot-row {
    display: flex;
    justify-content: center;
    overFlow: hidden;
    margin: 4px 0
  }
  
  .dot-row .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: black;
    margin: 0 2px;
  }
  .container_image {
    position: absolute;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .container_image img {
    width: 140px;
    height: 140px;
    margin-left: -10%;
    margin-top: 2%;
  }
  .title_with_dots {
    float: left;
    margin-right: 6px;
    font-weight: 600;
    font-size: 16px;
    }
.font-Playfair {
    font-family: 'Playfair Display', serif;
} 
.font-Roboto {
    font-family: 'Roboto', sans-serif;
} 
.float-left {
    float: left;
} 
.font-size-text {
    font-size: 14px
} 
.space-row {
    margin-top: 120px;
}
/* .react-calendar__month-view__days__day--neighboringMonth {
  color: #932222;
} */
.react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury {
  color: #bfb7b7;
}
/* .react-calendar__tile:enabled:focus {
  background-color: #4CAF50
}
.react-calendar__tile--active {
  background-color: #9d9d9d
} */